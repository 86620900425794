import MicroModal from "micromodal";

class FormRequest {
    constructor(selector) {
        this.requestForms = document.querySelectorAll(selector);
        return this.onSubmitHandler();
    }
    onSubmitHandler() {
        this.requestForms.forEach(form => {
            form.addEventListener('submit', this.sendRequest);
        })
    }
    sendRequest(event) {
        event.preventDefault();
        let form = event.target;
        let data = new FormData(form);

        fetch(form.action, {
                method: form.method,
                credentials: 'same-origin',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": "XMLHttpRequest"
                },
                body: new URLSearchParams(data).toString()
            })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(new Error('Failed to load'));
                }
            })
            .then(response => response.json())
            .then(result => {
                const modals = {
                    'true': 'modal-success',
                    'false': 'modal-error'
                };
                document.querySelector(`#${modals[result.status]} .modal__subtext`).innerHTML = result.message;
                MicroModal.show(modals[result.status]);
                form.reset();
                if(result.status) {
                    if (!!window.send_calltouch){ window.send_calltouch(data.get('phone')); }
                    ym(93470343, 'reachGoal', 'zayavka');
                }
            })
            .catch(error => {
                console.log(`Error: ${error.message}`);
            });
    }
}
export default FormRequest;
