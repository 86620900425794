import sass from '../scss/main.scss';
import IMask from 'imask';
import Swiper from 'swiper';
import MicroModal from 'micromodal';
import FormRequest from './form-request.js';

new FormRequest('.request-form');

MicroModal.init({
    openTrigger: 'data-custom-open',
    disableScroll: true,
    disableFocus: false,
    awaitCloseAnimation: true,
});

const phoneInputs = document.querySelectorAll('[name="phone"]');
if (phoneInputs.length > 0) {
    phoneInputs.forEach(function (el) {
        let mask = IMask(el, {
            mask: [
                { mask: '{8} (000) 000-00-00', startsWith: '8' },
                { mask: '{+7} (000) 000-00-00', startsWith: '' }
            ],
            dispatch: function (appended, dynamicMasked) {
                let number = dynamicMasked.rawInputValue.replace(/\D/g,'');
                return dynamicMasked.compiledMasks.find(function (m) {
                    return number.indexOf(m.startsWith) === 0;
                });
            }
        });
    });
}

const messageImportant = document.getElementById('message-important');
if (messageImportant && !localStorage.getItem('messageImportantClose')) {
    messageImportant.classList.add('message-important--open');
    const messageImportantClose = messageImportant.querySelector('.message-important__close');
    messageImportantClose.addEventListener('click', function (e) {
        messageImportant.classList.remove('message-important--open');
        localStorage.setItem('messageImportantClose', JSON.stringify(true));
    })
}

/*
const heading = document.querySelector('h1');
if (heading) {
    if (heading.clientHeight > 200) { //больше 3-х строк
        heading.style.fontSize = '37px';
    }
}
*/

new Swiper('.reviews__slider', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 3,
    spaceBetween: 24,
    autoHeight: false,
    navigation: {
        nextEl: '.reviews__slider-wrapper .swiper-button-next',
        prevEl: '.reviews__slider-wrapper .swiper-button-prev',
    },
    pagination: {
        el: '.reviews__slider-wrapper .swiper-pagination',
        clickable: true
    },
});

new Swiper('.our-masters__slider', {
    direction: 'horizontal',
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoHeight: false,
    pagination: {
        el: '.our-masters__slider .swiper-pagination',
        clickable: true
    },
});

new Swiper('.portfolio__slider', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 2,
    spaceBetween: 30,
    autoHeight: false,
    navigation: {
        nextEl: '.portfolio__slider-wrapper .swiper-button-next',
        prevEl: '.portfolio__slider-wrapper .swiper-button-prev',
    },
    pagination: {
        el: '.portfolio__slider-wrapper .swiper-pagination',
        clickable: true
    },
});

const circleTimer = document.querySelectorAll('.circle-timer-js');
const timerRange = document.querySelectorAll('.timer-range-js');
if (circleTimer.length > 0 || timerRange.length > 0) {
    const date = new Date();
    const clientLocalHour = date.getHours();
    if (clientLocalHour >= 7 && clientLocalHour < 12) {
        if (circleTimer.length > 0) {
            for (let elem of circleTimer) {
                const a = new Date;
                const b = new Date;
                b.setHours(12, 0, 0);
                const sec = parseInt(+b - +a) / 1000;
                elem.innerHTML = parseInt(sec / 3600) + 'ч. ' + parseInt((sec % 3600) / 60) + 'мин.';
            }
        }
        if (timerRange.length > 0) {
            for (let elem of timerRange) {
                elem.innerHTML = 'с 7:00 до 12:00';
            }
        }
    } else if (clientLocalHour >= 12 && clientLocalHour < 16) {
        if (circleTimer.length > 0) {
            for (let elem of circleTimer) {
                const a = new Date;
                const b = new Date;
                b.setHours(16, 0, 0);
                const sec = parseInt(+b - +a) / 1000;
                elem.innerHTML = parseInt(sec / 3600) + 'ч. ' + parseInt((sec % 3600) / 60) + 'мин.';
            }
        }
        if (timerRange.length > 0) {
            for (let elem of timerRange) {
                elem.innerHTML = 'с 12:00 до 16:00';
            }
        }
    } else if (clientLocalHour >= 16 && clientLocalHour < 24) {
        if (circleTimer.length > 0) {
            for (let elem of circleTimer) {
                const a = new Date;
                const b = new Date;
                b.setHours(24, 0, 0);
                const sec = parseInt(+b - +a) / 1000;
                elem.innerHTML = parseInt(sec / 3600) + 'ч. ' + parseInt((sec % 3600) / 60) + 'мин.';
            }
        }
        if (timerRange.length > 0) {
            for (let elem of timerRange) {
                elem.innerHTML = 'с 16:00 до 24:00';
            }
        }
    }
    else {
        if (circleTimer.length > 0) {
            for (let elem of circleTimer) {
                const a = new Date;
                const b = new Date;
                b.setHours(7, 0, 0);
                const sec = parseInt(+b - +a) / 1000;
                elem.innerHTML = parseInt(sec / 3600) + 'ч. ' + parseInt((sec % 3600) / 60) + 'мин.';
            }
        }
        if (timerRange.length > 0) {
            for (let elem of timerRange) {
                elem.innerHTML = 'с 0:00 до 7:00';
            }
        }
    }
}

const hostName = document.querySelectorAll(".host-name-js");
if (hostName.length > 0) {
    hostName.forEach(function (elem) {
        elem.innerHTML = location.hostname;
    });
}

const faqItem = document.querySelectorAll('.faq__item');
if (faqItem.length > 0) {
    faqItem.forEach(function (el) {
        el.addEventListener('click', function (e) {
            if (e.target.classList.contains('faq__title')) {
                e.preventDefault();
                this.classList.toggle('active');
            }
        })
    });
}

const priceItem = document.querySelectorAll('.price__item');
if (priceItem.length > 0) {
    priceItem.forEach(function (el) {
        el.addEventListener('click', function (e) {
            if (e.target.classList.contains('price__title')) {
                e.preventDefault();
                this.classList.toggle('active');
            }
        })
    });
}

const ourMastersItem = document.querySelectorAll('.our-masters__item');
if (ourMastersItem.length > 0) {
    ourMastersItem.forEach(function (el) {
        el.addEventListener('click', function (e) {
            if (e.target.classList.contains('our-masters__master-name')) {
                e.preventDefault();
                this.classList.toggle('active');
            }
        })
    });
}

let selectLocation = document.querySelector('#select-location');
if (selectLocation) {
    window.addEventListener('load', event => {
        fetch('/location.php?r=get-list')
            .then(response => response.json())
            .then(data => {
                let selectHtml = '<option disabled selected>Выберите станцию</option>';
                for (let key in data) {
                    selectHtml += "<option value='" + key + "'>" + data[key] + "</option>"
                }
                selectLocation.innerHTML = selectHtml;
            })
            .catch(error => {
                console.log(`Error: ${error.message}`);
            });
    });

    selectLocation.addEventListener('change', event => {
        let location = event.target.value,
            btn = document.querySelector('#show-masters');

        btn.setAttribute('href', '/location.html?metro=' + location);
    });
}

let locationPage = document.querySelector('.location-page');
if (locationPage) {
    let urlParams = new URLSearchParams(window.location.search),
        location = urlParams.get('metro');

    window.addEventListener('load', event => {
        fetch('/location.php?r=get-data&slug=' + location)
            .then(response => response.json())
            .then(data => {
                let locationName = document.querySelectorAll('.location-name'),
                    locationMap = document.querySelector('#location-map');
                [...locationName].map(i => i.innerHTML = data['name']);
                let imageName = '/images/geo/metro/' + data['image'];
                locationMap.style.backgroundImage = "url(" + imageName + ")";
            })
            .catch(error => {
                console.log(`Error: ${error.message}`);
            });
    })

}